import {
  COMPLETE_TRANSACTION,
  SET_TRANSACTION_RESPONSE,
  PaymetStatusState,
  PaymentStatusActionType
} from "./types";

const initialState: PaymetStatusState = {
  transactionCompleted: false,
  transactionResponse: null,
}

export function PaymentStatusReducer(
  state: PaymetStatusState = initialState,
  action: PaymentStatusActionType
): PaymetStatusState {
  switch (action.type) {
    case COMPLETE_TRANSACTION: {
      const { payload } = action;
      const { transactionResponse } = payload;

      return {
        ...state,
        transactionCompleted: true,
        transactionResponse,
      };
    }

    case SET_TRANSACTION_RESPONSE: {
      const { payload} = action;
      const { transactionResponse } = payload;

      return {
        ...state,
        transactionResponse,
      };
    }

    default: {
      return state;
    }
  }
}
