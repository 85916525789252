import React, { useState } from "react";
import styled from "styled-components";
import PocketLogo from "./assets/pocket-brand.svg";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";
import { PocketId } from "./views/PocketId";
import { Authorization } from "./views/Authorization";
import { HowToPay } from "./views/HowToPay";
import { Success } from "./views/Success";

export type PocketView =
  | "pocket-id"
  | "authorization"
  | "how-to-pay"
  | "success";

interface Props {
  paymentParams: PaymentParams;
}

const Pocket = ({ paymentParams }: Props) => {
  const [view, setView] = useState<PocketView>("pocket-id");

  const renderView = () => {
    switch (view) {
      case "pocket-id":
        return <PocketId setView={setView} params={paymentParams} />;
      case "authorization":
        return <Authorization setView={setView} params={paymentParams} />;
      case "how-to-pay":
        return <HowToPay setView={setView} />;
      case "success":
        return <Success params={paymentParams} />;
      default:
        return <PocketId setView={setView} params={paymentParams} />;
    }
  };

  const renderDescription = () => {
    switch (view) {
      case "pocket-id":
        return "Kindly enter your Pocket ID and click on the next button below to continue.";
      case "authorization":
        return "Authorize payment from the Pocket App";
      case "how-to-pay":
        return "How to Pay With MoMo";
      default:
        return "Kindly enter your phone number and click on the button below to continue.";
    }
  };

  return (
    <Container>
      <LogoHeader view={view}>
        <img src={PocketLogo} alt="Pocket" />

        <div style={{ paddingLeft: "14px" }}>
          <h4>Pay with Pocket</h4>
          <p>{renderDescription()}</p>
        </div>
      </LogoHeader>
      {renderView()}
    </Container>
  );
};

export default Pocket;

const Container = styled.div`
  width: 100%;
`;

const LogoHeader = styled.div<{ view: PocketView }>`
  display: ${({ view }) => (view === "success" ? "none" : "flex")};
  align-items: center;
  margin-bottom: 24px;

  img {
    width: 48px;
    height: 48px;
  }

  h4 {
    font-size: ${({ view }) => (view === "how-to-pay" ? "1.2rem" : "1.8rem")};
    font-weight: ${({ view }) => (view === "how-to-pay" ? "400" : "600")};
    opacity: ${({ view }) => (view === "how-to-pay" ? "0.8" : "1")};
    color: #152a38;

    margin-bottom: 4px;
  }

  p {
    color: #181b24;
    font-size: ${({ view }) => (view === "how-to-pay" ? "1.6rem" : "1.2rem")};
    font-weight: ${({ view }) =>
      view === "how-to-pay" || view === "authorization" ? "500" : "400"};
    opacity: ${({ view }) =>
      view === "how-to-pay" || view === "authorization" ? "1" : "0.6"};
    line-height: 16.8px;
  }
`;
