import httpClient from "../../../../core/api/httpClient";
import { SERVICE_BASE_URL } from "../../../../config/properties";

interface InitialisePayload {
  merchantCode: string;
  payableCode: string;
  currencyCode: string;
  transactionReference: string;
  paymentId: number;
  walletId: string;
}

interface StatusPayload {
  merchantCode: string;
  transactionReference: string;
}

const initialize = async (data: InitialisePayload) => {
  return httpClient.post<any>(
    `${SERVICE_BASE_URL}/api/v1/pocket/initialize`,
    data
  );
};

const authorize = async (data: StatusPayload) => {
  return httpClient.post<any>(`${SERVICE_BASE_URL}/api/v1/pocket/status`, data);
};

const PocketRepository = {
  initialize,
  authorize,
};

export default PocketRepository;
