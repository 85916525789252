import React from "react";
import styled from "styled-components";
import MethodItem from "../../../../components/MethodItem";
import { useHistory } from "react-router-dom";
import {
  PAYMENT__MOMO_ROOT,
  PAYMENT__ENAIRA_ROOT,
  PAYMENT__PALMPAY_ROOT,
  PAYMENT__POCKET_ROOT,
} from "../../../../Routes";
import BackControl from "../../../../components/BackControl";
import { PageViewAnimationStyles } from "../../../../components/Layout";
import eNairaIconPath from "../icons/enaira.webp";
// import momoIcon from "../icons/momo.svg";
// import pocketIcon from "../icons/pocket.svg";

type CustomerWalletType = "momo" | "enaira" | "palmpay" | "pocket" | "other";

interface CustomerWalletOption {
  type: CustomerWalletType;
  title: string;
  iconPath: string;
}

const customerWalletOptions: CustomerWalletOption[] = [
  {
    type: "enaira",
    title: "eNaira",
    iconPath: eNairaIconPath,
  },
  // {
  //   type: "momo",
  //   title: "MoMo wallet",
  //   iconPath: momoIcon,
  // },
  // {
  //   type: "pocket",
  //   title: "Pocket",
  //   iconPath: pocketIcon,
  // },
  // {
  //   type: "palmpay",
  //   title: "PalmPay",
  //   iconPath: palmPayIcon,
  // },
];

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${PageViewAnimationStyles};
`;

export default function RootPage() {
  const history = useHistory();

  const onOptionSelect = (type: CustomerWalletType) => {
    if (type === "momo") {
      history.push(PAYMENT__MOMO_ROOT);
    }

    if (type === "enaira") {
      history.push(PAYMENT__ENAIRA_ROOT);
    }

    if (type === "palmpay") {
      history.push(PAYMENT__PALMPAY_ROOT);
    }

    if (type === "pocket") {
      history.push(PAYMENT__POCKET_ROOT);
    }
  };

  const backControlHandler = () => {
    history.goBack();
  };

  return (
    <Container>
      <BackControl
        text="Change payment method"
        padded
        onClick={backControlHandler}
      />

      {customerWalletOptions.map((option) => {
        const { type, title, iconPath } = option;

        return (
          <MethodItem
            key={type}
            type={type}
            title={title}
            iconPath={iconPath}
            onSelect={() => onOptionSelect(type)}
          />
        );
      })}
    </Container>
  );
}
