import React from "react";
import styled from "styled-components";
import { ReactComponent as RightArrowIcon } from "./icons/right-arrow-icon.svg";
import { PAGE_SIDE_PADDING } from "../../../styling/constants";
import { ColorTheme } from "../../../styling/theme";

export const MethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px ${PAGE_SIDE_PADDING}px 15px ${PAGE_SIDE_PADDING}px;
  border-bottom: 1px solid ${ColorTheme.sectionDividerColor};
  cursor: pointer;
`;

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  background-color: #f1f4f7;
  border-radius: 5px;

  svg {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }

  img {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }

  &.plain {
    background-color: transparent;

    svg {
      height: 100%;
      width: auto;
    }
  }
`;

export const DetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;

  h6 {
    font-size: 1.4rem;
    margin-bottom: 5px;
    color: #152a38;
  }
  p {
    font-size: 1rem;
    color: #181b24;
    opacity: 0.8;
    line-height: 1.5;
  }
`;

export const ArrowIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    height: 10px;
    object-fit: contain;
  }
`;

interface Props {
  type: any;
  title: string;
  imageIcon?: React.ReactNode;
  iconPath?: string;
  description?: string;
  plainIcon?: boolean;
  onSelect: (val: any) => void;
}

export default function MethodItem(props: Props) {
  const { type, title, description, imageIcon, iconPath, plainIcon, onSelect } =
    props;

  return (
    <MethodContainer onClick={() => onSelect(type)}>
      <IconContainer className={plainIcon ? "plain" : undefined}>
        {imageIcon && imageIcon}
        {iconPath && <img src={iconPath} alt="icon" />}
      </IconContainer>

      <DetailsContainer>
        <h6>{title}</h6>
        {description && <p>{description}</p>}
      </DetailsContainer>

      <ArrowIconContainer>
        <RightArrowIcon />
      </ArrowIconContainer>
    </MethodContainer>
  );
}
