const transactionSuccessCodes = ['00'];
const transactionInProgressCodes = ['09', 'S0'];
const transactionExpiredCodes = ['XS1'];
const transactionErrorCodes: string[] = [];



export class PaymentUtil {
  public static isTransactionSuccessful(responseCode: string) {
    return transactionSuccessCodes.indexOf(responseCode) !== -1;
  }

  public static isTransactionInProgress(responseCode: string) {
    return transactionInProgressCodes.indexOf(responseCode) !== -1;
  }

  public static isTransactionExpired(responseCode: string) {
    return transactionExpiredCodes.indexOf(responseCode) !== -1;
  }

  public static isTransactionComplete(responseCode: string): boolean {
    if (
      PaymentUtil.isTransactionSuccessful(responseCode)
      || PaymentUtil.isTransactionInProgress(responseCode)
      || PaymentUtil.isTransactionExpired(responseCode)
    ) {
      return true;
    }

    return false;
  }
}