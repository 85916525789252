export const PAYMENT_METHODS__ROOT = "/payment-methods";

export const PAYMENT__CARD_ROOT = "/payment/card";
export const PAYMENT__CARD_PIN = "/payment/card/pin";
export const PAYMENT__CARD_OTP = "/payment/card/otp";
export const PAYMENT__CARD_CARDINAL = "/payment/card/cardinal";

export const PAYMENT__WALLET_LOGIN = "/payment/wallet/login";
export const PAYMENT__WALLET_ROOT = "/payment/wallet";
export const PAYMENT__WALLET_CARD_PAGE = "/payment/wallet/card";

export const PAYMENT__TRANSFER_ROOT = "/payment/transfer";

export const PAYMENT__QR_ROOT = "/payment/qr";

export const PAYMENT__USSD_ROOT = "/payment/ussd";

export const PAYMENT__CUSTOMER_WALLET_ROOT = "/payment/customerwallet";
export const PAYMENT__OPAY_ROOT = "/payment/opay";
export const PAYMENT__ENAIRA_ROOT = "/payment/enaira";
export const PAYMENT__PALMPAY_ROOT = "/payment/palmpay";
export const PAYMENT__MOMO_ROOT = "/payment/momo";
export const PAYMENT__POCKET_ROOT = "/payment/pocket";

export const PAYMENT__CREDIT = "/payment/credit";

export const PAYMENT__GOOGLE_PAY = "/payment/google";

export const PAYMENT__PAY_WITH_APP = "/payment/pay-with-app";

export const PAYMENT__UGANDA_MOBILE_MONEY = "/payment/uganda-mobile-money";

export const ZIB__CUSTOMER_DISCLAIMER = "/payment/zib-disclaimer";
