import React from 'react';
import styled from 'styled-components';

import { PageView } from '../../components/Layout';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-icon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-icon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-icon.svg';
import { PaymentUtil as PU } from '../util/PaymentUtil';
import { AppDisplayMode } from './store/paymentProperties/types';

type TransactionStatus = "SUCCESS" | "IN_PROGRESS" | "EXPIRED" | "CANCELLED" | "FAILURE";

const getTransactionStatus = (responseCode: string): TransactionStatus => {
  if (PU.isTransactionSuccessful(responseCode)) return "SUCCESS";

  if (PU.isTransactionInProgress(responseCode)) return "IN_PROGRESS";

  if (PU.isTransactionExpired(responseCode)) return "EXPIRED";

  if (responseCode === 'Z6') return "CANCELLED";

  return "FAILURE";
};

const Container = styled(PageView)`
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-bottom: 20px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 10px;
  
  h6 {
    font-size: 1.6rem;
  }
`;

const Message = styled.p`
  text-align: center;
`;

interface Props {
  appDisplayMode: AppDisplayMode;
  merchantName: string;
  transactionResponse: any;
}

export default function PaymentCompleteView(props: Props) {
  const {
    appDisplayMode,
    merchantName,
    transactionResponse
  } = props;

  const transactionStatus = getTransactionStatus(
    transactionResponse.responseCode
  );

  return (
    <Container>
      <IconContainer>
        {transactionStatus === "SUCCESS" && <SuccessIcon />}

        {transactionStatus === "IN_PROGRESS" && <WarningIcon />}
        {transactionStatus === "CANCELLED" && <WarningIcon />}

        {transactionStatus === "EXPIRED" && < ErrorIcon />}
        {transactionStatus === "FAILURE" && <ErrorIcon />}
      </IconContainer>

      <TitleContainer>
        {transactionStatus === "SUCCESS" && (
          <TitleContainer>
            <h6>Payment Successful</h6>
          </TitleContainer>
        )}

        {transactionStatus === "IN_PROGRESS" && (
          <TitleContainer>
            <h6>Transaction in Progress</h6>
          </TitleContainer>
        )}

        {transactionStatus === "EXPIRED" && (
          <TitleContainer>
            <h6>Transaction Expired</h6>
          </TitleContainer>
        )}

        {transactionStatus === "CANCELLED" && (
          <TitleContainer>
            <h6>Payment Cancelled</h6>
          </TitleContainer>
        )}
        {transactionStatus === "FAILURE" && (
          <TitleContainer>
            <h6>Payment Failed</h6>
          </TitleContainer>
        )}
      </TitleContainer>

      {appDisplayMode === "REDIRECT" && (
        <Message>You’re being redirected to <b>{merchantName}</b>, <br />please do not close this page.</Message>
      )}

      {appDisplayMode === "INLINE" && (
        <Message>We’re taking you back to <b>{merchantName}</b>, <br />please do not close this page.</Message>
      )}
    </Container>
  );
}