export const COMPLETE_TRANSACTION = 'PAYMENT.PAYMENT_STATUS.COMPLETE_TRANSACTION';
export const SET_TRANSACTION_RESPONSE = 'PAYMENT.PAYMENT_STATUS.SET_TRANSACTION_RESPONSE';

export interface CompleteTransactionPayload {
  transactionResponse: any;
}

export interface CompleteTransaction {
  type: typeof COMPLETE_TRANSACTION;
  payload: CompleteTransactionPayload;
}

export interface SetTransactionResponse {
  type: typeof SET_TRANSACTION_RESPONSE;
  payload: { transactionResponse: any };
}

export type PaymentStatusActionType =
  CompleteTransaction
  | SetTransactionResponse;

export interface PaymetStatusState {
  transactionCompleted: boolean;
  transactionResponse: any;
}