import * as React from "react";
import styled from "styled-components";

import { Button } from "../../../../../components/Button";
import { GenerateAccountResponse } from "../../../../domain/repositories/TransferRepository";
import CopyWidget from "../../../../../components/CopyWidget";
import WarningMessage from "../../../../../components/WarningMessage";
import { getFormattedPrice } from "../../../../../../util/currencyUtil";

interface Props {
  accountNumber: GenerateAccountResponse;
  onDone: () => void;
  currencyCode: string;
}
export default function BankInfo(props: Props) {
  const { accountNumber, onDone, currencyCode } = props;

  return (
    <CContainer>
      <p style={{ marginBottom: "16px" }}>
        Transfer exactly{" "}
        <span style={{ fontWeight: 900, fontSize: "14px" }}>
          {getFormattedPrice(accountNumber.amount, currencyCode)}
        </span>{" "}
        (including the decimal) to the account below
      </p>

      <WarningMessage
        message={`Do NOT transfer more than once to the account below or save it for later use`}
        color="#F05050"
        bg="#F050501A"
        borderColor="#F0505066"
      />

      <ValuesContainer>
        <ValueContainer>
          <CopyLabelContainer>
            <ValueLabel>Account Number</ValueLabel>
          </CopyLabelContainer>

          <div style={{ display: "flex", alignItems: "center" }}>
            <AccountNumber>{accountNumber.accountNumber}</AccountNumber>
            <CopyWidget text={accountNumber.accountNumber} />
          </div>
        </ValueContainer>

        <ValueContainer>
          <ValueLabel>Bank Name</ValueLabel>
          <p>{accountNumber.bankName}</p>
        </ValueContainer>

        <ValueContainer>
          <ValueLabel>Beneficiary</ValueLabel>
          <p>{accountNumber.accountName}</p>
        </ValueContainer>

        <ValueContainer>
          <ValueLabel>Validity</ValueLabel>
          <p>{accountNumber.validityPeriodMins} minutes</p>
        </ValueContainer>
      </ValuesContainer>

      <Button
        color="PRIMARY"
        text="I have transferred"
        className="action"
        onClick={onDone}
      />
    </CContainer>
  );
}

const CContainer = styled.div`
  margin-top: 5px;

  p {
    font-size: 1.2rem;

    .amount {
      color: #009fe4;
      font-size: 1.3rem;
    }
  }

  .message {
    margin-bottom: 20px;
  }

  > .action {
    width: 100%;
    font-weight: 400;
  }
`;

const ValuesContainer = styled.div`
  margin: 20px 0px;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 8px 16px;
`;

const ValueContainer = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  :not(:last-child) {
    border-bottom: 1px solid #e0e0e06e;
  }

  & > p:last-child {
    font-size: 1.4rem;
    font-weight: 900;
    text-align: right;
    color: black;
  }
`;

const ValueLabel = styled.p`
  font-size: 1.2rem;
  padding-right: 10px;
  box-sizing: border-box;
  white-space: nowrap;
`;

const CopyLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AccountNumber = styled.p`
  font-weight: 900;
  color: black;
  font-size: 1.4rem !important;
`;
